.header {
  position: relative;
  display: flex;
  flex-grow: 1;
  letter-spacing: 1px;
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #4d3f78;
  align-items: center;
  justify-content: right;
  height: 65px;
}

nav {
  display: flex;
}

.push-left {
  margin: 0 auto 0 1em;
  z-index: 1004;
}
.nav-link{
  text-decoration:none;
  color:inherit;
}
.username{
  font-size: 0.5em;
  margin-left: 0.5em;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  letter-spacing: 1px;
}