.main {
    min-height: 100vh;
  }

.dashboardTitle {
    font-size: 50px;
    text-align: center;
    margin-bottom: 20px;
    color: #4D3F78;
  }

body {
  overflow: hidden;
}

/* .fixedFooter {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    background-color: #4D3F78;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
  } */
  
