.sidebar {
  background: rgb(255, 255, 255);
  display:flex;
  flex-direction:column;
  width:100%;
  height: calc(100% - 48px);
  overflow-y: scroll;
}

.sidebar_mobile_wrapper{
  height:40vh;
  width:100%;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  z-index: 1002;
}
.open-sidebar {
  transform: translateX(0);
}

@media (min-width: 950px) {
  .sidebar {
    width: 200px;
    background: rgb(255, 255, 255);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    height: calc(100vh - 65px - 60px);
    z-index: 1002;
    overflow-y: scroll;
  }
  .sidebar_mobile_wrapper{
    display:none;
  }
}
@media (min-width:1100px){
  .sidebar{
    width:300px;
  }
}
