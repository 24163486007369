body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.esri-view-height-less-than-medium .esri-popup__main-container {
  max-height: 385px;
}

div.esri-component.esri-legend.esri-widget.esri-widget--panel {
  width: 184px;
  max-height: 260px;
}