.auth {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 450px;
  border-radius: 6px;
  background-color: #FFFFFF;
  box-shadow: 0px 11px 35px 2px rgba(0, 0, 0, 0.14);
  padding: 1.75rem 1rem 1.75rem 1rem;
  text-align: center;
}

.auth h1 {
  margin: 0;
  margin-bottom: 1rem;
  text-align: center;
  color: rgb(100, 100, 100);
}

.control {
  margin-bottom: 1.25rem;
}

form>* .control {
  background: red;
}

.control label {
  display: block;
  color: rgb(100, 100, 100);
  font-weight: light;
  margin: 0 auto 0.6rem auto;
  width: 80%;
  text-align: left;
}

.control input {
  font: inherit;
  background-color: rgba(113, 93, 162,0.15);
  border: 1px solid rgba(113, 93, 162,0.15);
  border-radius: 4px;
  width: 80%;
  text-align: left;
  padding: 0.25rem;
}

.actions {
  margin-top: 2.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.actions button {
  cursor: pointer;
  font: inherit;
  color: white;
  background-color: #4d3f78;
  border: 1px solid #4d3f78;
  border-radius: 4px;
  padding: 0.5rem 2.5rem;
}

.actions button:hover {
  background-color: #614f96
  ;
  border-color: #614f96
  ;
}

.actions .toggle {
  margin-top: 1rem;
  background-color: transparent;
  color: rgba(113, 93, 162,0.15);
  border: none;
  padding: 0.15rem 1.5rem;
}

.actions .toggle:hover {
  background-color: transparent;
  color: #614f96
  ;
}