html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  font-family: 'Roboto', sans-serif;
}

.sidebarBoxContainer {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}

/*******************Overwrite defualt styling*************************/

/* Basemaps gallery*/

.esri-view-width-greater-than-large .esri-ui-corner .esri-basemap-gallery.esri-component .esri-basemap-gallery__item-container {
  flex-flow: column;
}

.esri-basemap-gallery__item {
  margin: 0 !important;
  flex-flow: row !important;
  width: 100% !important;
  padding: 6px 7px !important;
}

.esri-basemap-gallery__item-title {
  margin: 0 auto !important;
}

.esri-basemap-gallery__item-title {
  margin: auto;
}

/* Popup customization*/

.esri-widget__heading.esri-popup__header-title{
  text-align: center;
}