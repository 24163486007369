.form {
  width: 95%;
  max-width: 25rem;
  margin: 2rem auto;
}

.control {
  margin-bottom: 1.5rem;
}

.control label {
  display: block;
  color: rgb(100, 100, 100);
  font-weight: light;
  margin: 0 0 0.6rem 0;
  width: 80%;
  text-align: left;
}

.control input {
  box-sizing: border-box;
  display: block;
  font: inherit;
  width: 100%;
  border-radius: 4px;
  background-color: rgba(113, 93, 162,0.15);
  border: 1px solid rgba(113, 93, 162,0.15);
  padding: 0.25rem;
}

.action {
  margin-top: 1.5rem;
  display:flex;
  justify-content:space-between;
}

.action button {
  font: inherit;
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  background-color: #4d3f78;
  border: 1px solid #4d3f78;
  color: white;
}
.action button:hover {
  background-color: #614f96;
  border-color: #614f96;
}

.cancel-btn {
  background: white !important;
  color: #4d3f78 !important;
  border: none !important;
}
.cancel-btn:hover{
  color: #614f96 !important;
}