/* Impostazioni generali */
* {
  box-sizing: border-box;
}

body {
  overflow: hidden;
}

/* Sezione sinistra */
.leftSection {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; */
  float: left;
  width: 15%;
  height: 100vh;
  background-color: #f2f2f2;
  position: fixed;
  z-index: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.leftSection h2 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #4d3f78;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
}

.leftSection button {
  width: 100%;
  max-width: 180px;
  height: 40px;
  margin-top: 30px;
  margin-right: 20px;
  background-color: #4D3F78;
  border: none;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
}

.leftSection a {
  text-decoration: none;
  color: #fff;
  font-size: 0.9rem;
  justify-content: center;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .leftSection button {
    /* width: calc(50% - 15px); */
    width: 50%;
  }
}

/* Sezione destra */
.rightSection {
  position: fixed;
  right: 0;
  float: right;
  width: 15%;
  height: 100vh;
  background-color: #f2f2f2;
  z-index: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.3);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rightSection h2 {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
  color: #4d3f78;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 10px;
  background-color: #f2f2f2;
}


/* Sezione centrale */
  
.centerSection {
  margin-left: 15%;
  margin-right: 15%;
  height: 100vh;
  overflow-y: scroll;
}

/* Titolo */
.title {
  font-size: 3rem;
  text-align: center;
  margin: 20px 10% 20px 5%;
  color: #4D3F78;
}

.subtitle {
  margin-top: 0;
  font-size: 1.5rem;
  margin-bottom: 10px;
  text-align: center;
  color: #4D3F78;
}



/* Impostazioni per i contenuti */
.content {
  padding: 2rem;
}

/* Stili per dispositivi mobili */
@media only screen and (max-width: 768px) {
  .leftSection,
  .rightSection {
    width: 100%;
    height: auto;
    position: static;
  }
  
  .centerSection {
    margin: 0;
  }
}

/* Stili per indicatori */
.indicatorContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px 5% 20px 5%;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
}
/* .indicatorContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px 5% 20px 5%;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
} */
  
.indicatorContainer h2 {
  width: 100%;
  font-size: 2rem;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 20px;
  color: #4D3F78;
}

.indicatorBox {
  background-color: transparent;
  text-align: center;
  margin-bottom: 20px;
  /* margin-right: 80px; commentato solo per la consegna iniziale poi va riutilizzato con più indicatori*/
  margin-right: 25px;
  max-width: 50%;
}

@media only screen and (max-width: 768px) {
  .indicatorBox {
  max-width: 100%; /* nuovo */
  }
}

.indicator {
  width: 120px;
  height: 120px;
  background-color: #eee;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  text-align: center;
}

.indicatorValue {
  font-size: 30px;
  font-weight: bold;
}
.indicatorValue .CircularProgressbar-path {
  stroke: none;
}
  
.indicatorValue .CircularProgressbar-text {
  fill: none;
}

.indicatorLabel {
  font-size: 13.5px;
  margin-top: 10px;
  color: #4d3f78
}

.datsBox {
  background-color: transparent;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
}

.dats {
  width: 90px; /* ridurre le dimensioni del cerchio */
  height: 90px; /* ridurre le dimensioni del cerchio */
  background-color: #eee;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
  text-align: center;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
}

.datsValue {
  font-size: 24px; /* ridurre la dimensione del font del numero */
  font-weight: bold;
  color: #4D3F78;
}

.datsLabel {
  font-size: 13.5px;
  margin-top: 10px;
  color: #4D3F78;
  font-weight: bold;

}

/* Grafici */
.chartContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 20px 5% 20px 5%;
  box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
}

.chart {
  /* width: calc(50% - 20px); */
  /* width: 1000px; */
  max-width: 100%;
  height: 440px;
  margin: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 20px;
  /* box-sizing: border-box; */
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.chartInTime {
  max-width: 100%;
  height: 550px;
  margin: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding: 20px;
  /* box-sizing: border-box; */
  justify-content: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.chart .chartInTime h2{
  width: 100%;
  text-align: center;
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: #4D3F78;
}

.chart .chartInTime h3 {
  width: 100%;
  text-align: center;
  font-size: 12px;
  margin-top: px;
  margin-bottom: 20px;
  color: #4D3F78;
}


.chartContainer h2 {
  width: 100%;
  text-align: center;
  margin-top: px;
  margin-bottom: 20px;
  color: #4D3F78;
}

.containerDownload {
  text-align: right;
  align-items: center;
  margin-right: 5%;
  margin-bottom : 2px;
}

/* .chart {
  width: calc(50% - 20px);  
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.3);
} */

@media screen and (min-width: 768px) {  
  .chart {
    width: calc(33.33% - 20px);
  }
}

@media screen and (max-width: 767px) {  
  .chart {
    width: calc(50% - 15px);
  }
  
  .chart:nth-child(2n+1) {
    margin-right: 0;
  }
  
  .chart:last-child:nth-child(2n+1) {
    margin-right: auto;
  }
  
  .chart:last-child:nth-child(odd) {
    margin-right: auto;
  }
  
  .chartContainer {
    justify-content: center;
  }
}
