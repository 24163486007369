.subtitle {
  margin: 1rem 0 0 1rem;
}

.wrapper_disabled{
  opacity:0.4;
}
.card_disabled{
  background: rgb(255 255 255 / 8%);
  pointer-events: none;
}

@media(min-width:500px){
  .wrapper{
    display: grid;
    grid-auto-flow:column;
    grid-auto-columns:1fr;
  
  }
}
@media(min-width:950px){
  .wrapper{
    display: block;
  }
}
