.map_container{
  height: calc(100vh - 65px - 60px - 40vh);
  width:100%;
}

@media (min-width: 950px) {
  .map_container {
    width: calc(100% - 400px);
    height: calc(100vh - 65px - 60px);
  }
}

@media (min-width: 1100px) {
  .map_container {
    width: calc(100% - 600px);
  }
}