.container {
    text-align: right;
    align-items: center;
    margin-right: 5%;
}

.BsFillImageFill {
    color: #82ca9d;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
    margin-right: 5px;
}

.BsFiletypeCsv {
    color: #82ca9d;
    box-shadow: 0 4px 8px 2px rgba(0, 0, 0, 0.3);
}

.infoText {
    font-size: 13px;
    line-height: 1.5;
    color: #333;
  }