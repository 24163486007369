.buttons {
  display: flex;
  justify-content: space-around;
}
.title {
  text-align: center;
  margin: 0 0 0.5em 0;
}
@media(min-width:950px) and (max-width:1100px){
  .title{
    font-size: 0.9em;
  }
  .buttons {
    flex-direction: column;
    align-items: center;
  }
}
