.container{
  display: flex;
  height: 60px;
  align-items: center;
  background-color:rgb(240, 240, 240);
}
.footer{
  width: 100%;
  font-size: 0.55em;
  text-align: center;
  padding:0.7em;
  bottom: 0;
}
.mail_link{
  color:black;
  text-decoration: none;
  border-bottom: 1px solid rgb(170, 170, 170);
}
.mail_link:hover,
.mail_link:focus{
background-color: rgb(223, 223, 223);
border-bottom: 1px solid black;
}
.logo_icon{
  width: 100%;
  max-width:45px;
  height: auto;
}
.logo_link{
  margin-left: 0.25em;
}

@media(min-width:400px){
  .footer{
    font-size: 0.65em;
  }
}
@media(min-width:1000px){
  .footer{
    font-size: 0.8em;
  }
}