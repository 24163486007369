.title {
  text-align: center;
  margin: 1.5em 0 0 0;
}
.rc-slider {
  position: relative;
  height: 40px;
  padding: 30px 0;
  width: 80%;
  margin: auto;
  border-radius: 6px;
  touch-action: none;
  box-sizing: border-box;
}
.rc-slider-mark-tex {
  margin-top: 1.5em;
}
@media(min-width:800px) and (max-width:1100px){
  .title{
    font-size: 0.9em;
  }
}
