.btn_center{
  text-align:center;
}
.analysebox_wrapper{
  margin:0 auto 1.5em auto;

}
@media(min-width:500px) and (max-width:950px){
  .analysebox_wrapper{
    width:500px;
  }
}
@media(min-width:950px){
  .analysebox_wrapper{
    margin:0 0 1.5em 0;
  }
}